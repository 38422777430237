import { useEffect, useState, useContext, useRef, useCallback } from "react";
import { Navigate } from "react-router-dom";
import axios from "./axios.js";
import { AuthContext } from "../App.jsx";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button, TextField, Typography } from "@mui/material";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import StoreIcon from "@mui/icons-material/Store";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

export const Dashboard = () => {
  const [orders, setOrders] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [totalOrders, setTotalOrders] = useState(null);
  const observer = useRef();
  const { isAuth } = useContext(AuthContext);

  const bouquetReturn = async (bouquetId) => {
    try {
      setOrders((prevOrders) =>
        prevOrders.filter((order) => order.bouquetId !== bouquetId)
      );
      await axios.patch(`/dashboard/${bouquetId}`);
    } catch (error) {
      console.error(error);
      alert("Не удалось загрузить заказы");
    }
  };

  const orderCheck = async (orderId) => {
    try {
      await axios.patch(`/create/${orderId}`);
      const { data } = await axios.get("/dashboard");
      setOrders(data);
    } catch (error) {
      console.error(error);
      alert("Не удалось загрузить заказы");
    }
  };

  const lastOrderRef = useCallback(
    (node) => {
      if (loading || (totalOrders !== null && orders.length >= totalOrders))
        return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          setCurrentPage((prev) => prev + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, orders.length, totalOrders]
  );

  useEffect(() => {
    const fetchOrders = async () => {
      if (loading) return;
      try {
        setLoading(true);
        const token = window.localStorage.getItem("token");
        const response = await axios.get(
          `/dashboard?page=${currentPage}&size=10`,
          {
            headers: { authorization: token },
          }
        );
        setOrders((prev) => [
          ...prev,
          ...response.data.filter(
            (order) => !prev.some((o) => o._id === order._id)
          ),
        ]);
        const total = response.headers["x-total-count"];
        if (total !== undefined) {
          setTotalOrders(parseInt(total, 10));
        }
      } catch (error) {
        console.error("Не удалось загрузить заказы", error);
      } finally {
        setLoading(false);
      }
    };

    if (totalOrders === null || orders.length < totalOrders) {
      fetchOrders();
    }
  }, [currentPage]);

  return isAuth ? (
    <>
      <TextField
        label="Поиск по номеру"
        variant="outlined"
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        style={{ margin: "15px 25px" }}
      />
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">
                <b>Телефон клієнта</b>
              </TableCell>
              <TableCell align="center">
                <b>Спосіб зв'язку</b>
              </TableCell>
              <TableCell align="center">
                <b>Фото</b>
              </TableCell>
              <TableCell align="center">
                <b>Доставка</b>
              </TableCell>
              <TableCell align="center">
                <b>Виконано</b>
              </TableCell>
              <TableCell align="center">
                <b>Адреса доставки</b>
              </TableCell>
              <TableCell align="center">
                <b>Телефон отримувача</b>
              </TableCell>
              <TableCell align="center">
                <b>Дата та час</b>
              </TableCell>
              <TableCell align="center">
                <b>Ціна</b>
              </TableCell>
              <TableCell align="center">
                <b>Номер замовлення</b>
              </TableCell>
              <TableCell align="center">
                <b>Повернути на вітрину</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.map((order, index) => (
              <TableRow
                key={order._id}
                ref={index === orders.length - 1 ? lastOrderRef : null}
              >
                <TableCell align="center">+{order.orderPhoneNumber}</TableCell>
                <TableCell align="center">{order.contactType}</TableCell>
                <TableCell align="center">
                  <LazyLoadImage
                    src={`https://app.vazaflower.com${order.imageUrl}`}
                    width={100}
                    height={100}
                    effect="blur"
                  />
                </TableCell>
                <TableCell align="center">
                  {order.isDelivery ? (
                    <LocalShippingIcon color="success" />
                  ) : (
                    <StoreIcon color="primary" />
                  )}
                </TableCell>
                <TableCell align="center">
                  <Button onClick={() => orderCheck(order._id)}>
                    {order.isComplete ? (
                      <AssignmentTurnedInIcon color="success" />
                    ) : (
                      <HelpCenterIcon color="primary" />
                    )}
                  </Button>
                </TableCell>
                <TableCell align="center">
                  {order.orderDeliveryAddress}
                </TableCell>
                <TableCell align="center">
                  {order.orderDeliveryNumber}
                </TableCell>
                <TableCell align="center">
                  {new Date(order.createdAt).toLocaleString()}
                </TableCell>
                <TableCell align="center">{order.price}</TableCell>
                <TableCell align="center">{order.orderId}</TableCell>
                <TableCell align="center">
                  <Button
                    onClick={() => bouquetReturn(order.bouquetId)}
                    color="error"
                  >
                    <ChangeCircleIcon />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  ) : (
    <Navigate to="/login" replace />
  );
};
