import axios from "./axios.js";
import { useContext, useEffect, useState } from "react";
import {
  Button,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  CircularProgress,
  Grid,
  Pagination,
} from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../App.jsx";

const Gallery = () => {
  const [oldCards, setOldCards] = useState([]);

  const [fetching, setFetching] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    try {
      async function fetchGalleryBouquets() {
        if (fetching) {
          const resCards = await axios.get(
            `/gallery?page=${currentPage}&size=9`
          );
          setOldCards(resCards.data.reverse());
        }
      }

      fetchGalleryBouquets();
    } catch (error) {
      console.error(error);
      alert("Ошибка при загрузке букетов с сервера: " + error);
    } finally {
      setFetching(false);
    }
  }, [fetching, currentPage]);

  const returnBouquet = async (id) => {
    if (window.confirm("Ви впевнені, що хочете повернути букет?")) {
      try {
        await axios.patch(`/bouquets/comeback/${id}`);
        setOldCards((prevCards) => prevCards.filter((card) => card._id !== id));
      } catch (error) {
        console.error(error);
      }
    }
  };

  const deleteBouquet = async (id) => {
    if (window.confirm("Точно-точно видалити букет?")) {
      await axios.delete(`/bouquets/${id}`);
      setOldCards((prevBouquets) =>
        prevBouquets.filter((bouquet) => bouquet._id !== id)
      );
    }
  };

  const paginationHandler = (e, page) => {
    setCurrentPage(page);
    setFetching(true);
    window.scrollTo(0, 0);
  };

  const { isAuth } = useContext(AuthContext);

  return isAuth ? (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 400 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell
              style={{ fontSize: "12px", textShadow: " 0 1px 1px #000" }}
              align="center"
            >
              <b> Створено</b>
            </TableCell>
            <TableCell
              style={{ fontSize: "12px", textShadow: " 0 1px 1px #000" }}
              align="center"
            >
              <b> Фото букета</b>
            </TableCell>
            <TableCell
              style={{ fontSize: "12px", textShadow: " 0 1px 1px #000" }}
              align="center"
            >
              <b>На вітрину</b>
            </TableCell>
            <TableCell
              style={{ fontSize: "12px", textShadow: " 0 1px 1px #000" }}
              align="center"
            >
              <b> Ціна</b>
            </TableCell>
            <TableCell
              style={{ fontSize: "12px", textShadow: " 0 1px 1px #000" }}
              align="center"
            >
              <b> Видалити букет</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {oldCards &&
            oldCards.map((bouquet) => {
              return (
                <TableRow
                  key={bouquet._id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    padding="none"
                    align="center"
                    sx={{ textShadow: " 0 1px 2px #000", fontSize: "12px" }}
                  >
                    {new Date(bouquet.createdAt).toLocaleString([], {
                      month: "2-digit",
                      day: "2-digit",
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  </TableCell>
                  <TableCell padding="none" align="center">
                    <LazyLoadImage
                      src={`https://app.vazaflower.com${bouquet.imageUrl}`}
                      alt="bouquet photo"
                      width={100}
                      height={100}
                      effect="blur"
                      placeholder={<CircularProgress color="success" />}
                    />
                  </TableCell>
                  <TableCell padding="none" align="center">
                    <Button
                      formEncType="multipart/form-data"
                      onClick={() => returnBouquet(bouquet._id)}
                      color="primary"
                      size="large"
                    >
                      <RotateLeftIcon fontSize="large" />
                    </Button>
                  </TableCell>
                  <TableCell
                    padding="none"
                    align="center"
                    sx={{ textShadow: " 0 1px 2px #000", fontSize: "14px" }}
                  >
                    {bouquet.price} грн
                  </TableCell>
                  <TableCell padding="none" align="center">
                    <Button
                      formEncType="multipart/form-data"
                      onClick={() => deleteBouquet(bouquet._id)}
                      color="error"
                      size="large"
                    >
                      <DeleteForeverIcon fontSize="large" />
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      <Grid container>
        <Pagination
          count={20}
          variant="outlined"
          page={currentPage}
          onChange={paginationHandler}
          sx={{ margin: "50px auto", textAlign: "center" }}
        />
      </Grid>
    </TableContainer>
  ) : (
    <Navigate to="/login" replace={true} />
  );
};

export default Gallery;
